<template>
  <div
    ref="pricing-module-box"
    class="pricing-module-box px-pricing-module-box"
    :class="`edit_${Xindex}_box`"
    :style="`background-image:url('${configs.bg}');background-color:${configs.bgColor};`"
    @click="clickItem('box')"
  >
    <div
      class="bigTitle"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
      @click="clickItem('bigTitle')"
    ></div>
    <div
      class="bigDesc"
      v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
      @click="clickItem('bigDesc')"
    ></div>

    <div
      v-if="isPc"
      ref="swiper_pc_box"
      class="swiper_box"
    >
      <div
        ref="swiper-pc-container"
        class="swiper-container swiper-container-one"
      >
        <div class="swiper-wrapper items">
          <!-- Swiper的内容 -->
          <div
            v-for="(item,index) in configs.items"
            :key="index"
            class="swiper-slide item"
          >
            <div
              class="head-box"
              :style="{backgroundColor:item.themeColor,backgroundImage:`url(${item.headImg})`}"
            >
              <div
                class="head-box__title"
                v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
              ></div>
              <div
                class="head-box__desc"
                v-html="$util.rex.getHtml(item[`${nowL}desc`] || item.desc)"
              ></div>
              <div
                v-if="item.tag.show"
                class="head-box__tag"
                :style="{backgroundColor:item.tag.bg}"
                v-html="$util.rex.getHtml(item.tag[`${nowL}text`] || item.tag.text)"
              ></div>
            </div>
            <div class="body-box">
              <div class="body-box__price-box">
                <div
                  class="body-box__price-box_price"
                  v-html="$util.rex.getHtml(item[`${nowL}price`] || item.price)"
                ></div>
                <div
                  class="body-box__price-box_desc"
                  v-html="$util.rex.getHtml(item[`${nowL}priceDesc`] || item.priceDesc)"
                ></div>
              </div>
              <div
                class="body-box__disDesc"
                v-html="$util.rex.getHtml(item[`${nowL}disDesc`] || item.disDesc)"
              >

              </div>
              <div
                v-if="item.btn.text"
                class="body-box__btn"
                :style="`${item.btn.type=='default' ? `backgroundColor:${item.themeColor};color:#FFF;` : `border:0.5px solid ${item.themeColor};color:#333;`}`"
                @click="$util.route.go(item.btn.go)"
                v-text="item.btn[`${nowL}text`] || item.btn.text"
              ></div>
              <div class="body-box__line"></div>
              <div
                v-for="ite,ind in item.child"
                class="body-box__cell "
                :class="`${ite.delete ? 'px-decoration' : ''}`"
              >
                <div class="body-box__cell-label">
                  <div
                    v-if="ite.labelH"
                    class="label_h"
                    v-html="ite.labelH"
                  ></div>
                  <div
                    class="label_v"
                    v-html="$util.rex.getHtml(ite[`${nowL}label`] || ite.label)"
                  ></div>
                </div>
                <div
                  v-if="ite.isTitle"
                  class="body-box__cell-value"
                  v-html="$util.rex.getHtml(ite[`${nowL}value`] || ite.value) "
                ></div>
                <div
                  v-else
                  class="body-box__cell-icon"
                >
                  <img :src="ite.icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="items">
        <div
          v-for="(item,index) in configs.items"
          :key="index"
          class="item"
        >
          <div
            class="head-box"
            :style="{backgroundColor:item.themeColor,backgroundImage:`url(${item.headImg})`}"
          >
            <div
              class="head-box__title"
              v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
            ></div>
            <div
              class="head-box__desc"
              v-html="$util.rex.getHtml(item[`${nowL}desc`] || item.desc)"
            ></div>
            <div
              v-if="item.tag.show"
              class="head-box__tag"
              :style="{backgroundColor:item.tag.bg}"
              v-html="$util.rex.getHtml(item.tag[`${nowL}text`] || item.tag.text)"
            ></div>
          </div>
          <div class="body-box">
            <div class="body-box__price-box">
              <div
                class="body-box__price-box_price"
                v-html="$util.rex.getHtml(item[`${nowL}price`] || item.price)"
              ></div>
              <div
                class="body-box__price-box_desc"
                v-html="$util.rex.getHtml(item[`${nowL}priceDesc`] || item.priceDesc)"
              ></div>
            </div>
            <div
              class="body-box__disDesc"
              v-html="$util.rex.getHtml(item[`${nowL}disDesc`] || item.disDesc)"
            >

            </div>
            <div
              v-if="item.btn.text"
              class="body-box__btn"
              :style="`${item.btn.type=='default' ? `backgroundColor:${item.themeColor};color:#FFF;` : `border:0.5px solid ${item.themeColor};color:#333;`}`"
              v-text="item.btn[`${nowL}text`] || item.btn.text"
            ></div>
            <div class="body-box__line"></div>
            <div
              v-for="ite,ind in item.child"
              class="body-box__cell "
              :class="`${ite.delete ? 'px-decoration' : ''}`"
            >
              <div class="body-box__cell-label">
                <div
                  v-if="ite.labelH"
                  class="label_h"
                  v-html="ite.labelH"
                ></div>
                <div
                  class="label_v"
                  v-html="$util.rex.getHtml(ite[`${nowL}label`] || ite.label)"
                ></div>
              </div>
              <div
                v-if="ite.isTitle"
                class="body-box__cell-value"
                v-html="$util.rex.getHtml(ite[`${nowL}value`] || ite.value) "
              ></div>
              <div
                v-else
                class="body-box__cell-icon"
              >
                <img :src="ite.icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
import { getByName } from "@/js/utils/default_config";
import ScrollTrigger from '@terwanerik/scrolltrigger';
const trigger = new ScrollTrigger();
import { gsap, TweenMax, TweenLite } from "gsap";
// Import Swiper Vue.js components
import Swiper from 'swiper';
// Import Swiper styles
import 'swiper/css/swiper.css';
export default {
  name: "pricing-module",
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          title: "",
          desc: "",
          items: []
        }
      }
    },
    Xindex: {
      default () {
        return '';
      }
    }
  },
  watch: {
    'configs.items.length' () {
      this.updateSwiper();
    }
  },
  computed: {
    isEdit () {
      return this.$store.getters.getIsEdit;
    },
    isPc () {
      return window.innerWidth > 768;
    }
  },
  mounted () {
    this.initSwiper();
    trigger.add(this.$refs['pricing-module-box'], {
      once: true,
      offset: {
        element: {
          x: 0,
          y: (trigger, rect, direction) => {
            return 0.2
          }
        },
        viewport: {
          y: (trigger, frame, direction) => {
            return 0;// trigger.visible ? 0 : 0.2
          }
        },
      },
      toggle: {
        callback: {
          in: () => {
            // 設置標題動畫
            TweenMax.from(this.$refs['pricing-module-box'].querySelector('.bigTitle'), {
              opacity: 0,
              y: 120,
              duration: 1,
              immediateRender: true
            })
            // 設置 描述動畫
            TweenMax.from(this.$refs['pricing-module-box'].querySelector('.bigDesc'), {
              opacity: 0,
              delay: 0.3,
              y: 120,
              duration: 1,
              immediateRender: true
            })
            TweenMax.from(this.$refs['pricing-module-box'].querySelector('.items'), {
              opacity: 0,
              delay: 0.3,
              y: 120,
              duration: 1,
              immediateRender: true
            })
          },
        }
      }
    })
  },
  methods: {
    updateSwiper () {
      this.mySwiper.destroy();
      setTimeout(() => {
        this.initSwiper();
      }, 300);
    },
    initSwiper () {
      if (this.isPc) {
        let items = this.configs.items;
        let len = items.length;
        let slidesPerView = 4.1;
        if (len == 4) {
          slidesPerView = 4
        } else if (len < 4) {
          slidesPerView = len;
        }
        this.mySwiper = new Swiper(this.$refs['swiper-pc-container'], {
          slidesPerView: slidesPerView,
          spaceBetween: 20,
          freeMode: true,
        })

        let swiperBox = this.$refs['swiper-pc-container'];
        let itemsBox = swiperBox.querySelector('.items')
        console.log('height', itemsBox.offsetHeight);

      }
    },
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x)
      }
    },
  }
}
</script>

<style lang="less" scoped>
@media screen and(min-width:768px) {
  .pricing-module-box {
    padding: 70px 116px 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .bigTitle {
      font-size: 44px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      text-align: center;
    }
    .bigDesc {
      font-size: 26px;
      font-family: "Microsoft Yahei";
      font-weight: 200;
      line-height: 40px;
      text-align: center;
      margin-top: 16px;
    }
    .items {
      margin-top: 50px;
      .item {
        border-radius: 16px;
        overflow: hidden;
        height: auto;
        // box-shadow: 0 2px 6px #ccc;
        border: 0.5px solid #ddd;
        box-sizing: border-box;
        margin-bottom: 20px;
        .head-box {
          padding: 30px;
          color: #fff;
          position: relative;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          .head-box__title {
            font-size: 26px;
            font-family: "Microsoft Yahei";
            font-weight: 200;
            letter-spacing: 2px;
          }
          .head-box__desc {
            font-size: 16px;
            font-family: "Microsoft Yahei";
            font-weight: 200;
            margin-top: 10px;
            letter-spacing: 2px;
          }
          .head-box__tag {
            position: absolute;
            right: -10%;
            top: 20%;
            font-size: 20px;
            font-family: "Microsoft Yahei";
            font-weight: 200;
            padding: 4px 0;
            width: 40%;
            text-align: center;
            transform: rotate(45deg);
          }
        }
        .body-box {
          // min-height: 600px;
          padding: 30px;
          .body-box__price-box {
            display: flex;
            align-items: baseline;
            .body-box__price-box_price {
              font-size: 28px;
              font-family: "Microsoft Yahei";
              font-weight: 600;
            }
            .body-box__price-box_desc {
              font-size: 16px;
              font-family: "Microsoft Yahei";
              font-weight: 500;
              margin-left: 6px;
            }
          }
          .body-box__disDesc {
            font-size: 16px;
            font-family: "Microsoft Yahei";
            font-weight: 500;
            margin-top: 6px;
          }
          .body-box__btn {
            font-size: 18px;
            font-family: "Microsoft Yahei";
            font-weight: 500;
            margin-top: 20px;
            height: 44px;
            line-height: 44px;
            border-radius: 6px;
            text-align: center;
            cursor: pointer;
          }
          .body-box__line {
            border-top: 0.5px solid #ddd;
            margin: 16px 0;
          }
          .body-box__cell {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2px 0;
            &.px-decoration {
              position: relative;
              color: #ccc;
              &::after {
                position: absolute;
                content: "";
                left: 0;
                right: 0;
                bottom: 50%;
                transform: translateY(-50%);
                height: 0.5px;
                background-color: #dded;
              }
            }
            .body-box__cell-label {
              display: flex;
              .label_h {
                font-size: 18px;
                margin-right: 6px;
              }
              .label_v {
                font-size: 18px;
                font-family: "Microsoft Yahei";
                font-weight: 500;
              }
            }
            .body-box__cell-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                max-width: 30px;
                max-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and(max-width:768px) {
  .px-pricing-module-box {
    padding: 34px 0 22px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .bigTitle {
      font-size: 22px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      text-align: center;
    }
    .bigDesc {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      text-align: center;
      padding: 0 50px;
      margin-top: 4px;
      margin-bottom: 20px;
    }
    .items {
      padding: 20px;
      .item {
        border-radius: 8px;
        overflow: hidden;
        // box-shadow: 0 2px 6px #ccc;
        border: 0.5px solid #ddd;
        box-sizing: border-box;
        margin-bottom: 20px;
        .head-box {
          padding: 10px;
          color: #fff;
          position: relative;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          .head-box__title {
            font-size: 18px;
            font-family: "Microsoft Yahei";
            font-weight: 200;
            letter-spacing: 2px;
          }
          .head-box__desc {
            font-size: 16px;
            font-family: "Microsoft Yahei";
            font-weight: 200;
            margin-top: 10px;
            letter-spacing: 2px;
          }
          .head-box__tag {
            position: absolute;
            right: -15%;
            top: 5%;
            font-size: 14px;
            font-family: "Microsoft Yahei";
            font-weight: 200;
            padding: 4px 0;
            width: 40%;
            text-align: center;
            transform: rotate(45deg);
          }
        }
        .body-box {
          // min-height: 600px;
          padding: 10px;
          .body-box__price-box {
            display: flex;
            align-items: baseline;
            .body-box__price-box_price {
              font-size: 20px;
              font-family: "Microsoft Yahei";
              font-weight: 600;
            }
            .body-box__price-box_desc {
              font-size: 14px;
              font-family: "Microsoft Yahei";
              font-weight: 500;
              margin-left: 6px;
            }
          }
          .body-box__disDesc {
            font-size: 14px;
            font-family: "Microsoft Yahei";
            font-weight: 500;
            margin-top: 6px;
          }
          .body-box__btn {
            font-size: 16px;
            font-family: "Microsoft Yahei";
            font-weight: 500;
            margin-top: 20px;
            height: 44px;
            line-height: 44px;
            border-radius: 6px;
            text-align: center;
          }
          .body-box__line {
            border-top: 0.5px solid #ddd;
            margin: 16px 0;
          }
          .body-box__cell {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px 0;
            &.px-decoration {
              position: relative;
              color: #ccc;
              &::after {
                position: absolute;
                content: "";
                left: 0;
                right: 0;
                bottom: 50%;
                transform: translateY(-50%);
                height: 0.5px;
                background-color: #dded;
              }
            }
            .body-box__cell-label {
              display: flex;
              .label_h {
                font-size: 14px;
                font-family: "Microsoft Yahei";
                font-weight: 500;
                margin-right: 6px;
              }
              .label_v {
                font-size: 14px;
                font-family: "Microsoft Yahei";
                font-weight: 500;
              }
            }
            .body-box__cell-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                max-width: 18px;
                max-height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
</style>